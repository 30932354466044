import React from "react"
import symbol from "../../assets/images/symbol_6.1.png"

const content = () => (
    <div className="content">
        <p>Kring Astrids fötter strök en katt och jag blev så glad över åsynen av det smidiga djuret som så hemtamt rörde sig kring familjen. Jag hade iakttagit scenen förut, där hundägare kastade sig om halsen på sina husdjur innan de omfamnade de människor som kommit för att möta dem. Jag hade alltid tyckt att det var sorgligt, men nu kände jag att det var det jag ville göra så jag knäböjde framför Saga och strök katten över den silkesmjuka ryggraden. När katten reste rygg mot min handflata kände jag mig som albatrossen. Vingpennorna hade självtorkat i solen och jag kunde spänna ut mina meter.</p>
        <p>Jag lyfte min lilla pojke till höften och hälsade genom att ta deras händer. Min hand i Andras. Det gick en stöt igenom mig varje gång jag nuddade hud. Jag kände mig konstig som reagerade så mycket på det. Hilda. Andras. Jag kände på dem, på deras namn. Nu skulle de ta plats i pojkens liv, och pojken få en plats i deras.<br />"Älskade vän", sade Hilda. "Älskade Sofie, jag dör så länge jag har väntat på det här".<br />Hon lade handen på min axel. Jag kunde inte möta hennes blick utan koncentrerade mig på himlen som strålade klarblå över henne. Fälten som dånade där bakom."<br />Var är mamma", frågade jag och jag kände hur Hilda tryckte till om min axel.<br />"Hon kommer snart, jag lovar", sade hon. Höll varmt. Tryckte varmt. Hon hade ett vitt ljus över sin solbruna panna. När jag tittade på henne såg jag henne så som hon sett ut när hon var liten. Jag såg hennes ansikte från när hon var tolv och fjorton, men även tjugotre och så nu. Jag såg alla de ansiktena hon burit och kanske även de hon skulle komma att bära och jag förstod att det alltid skulle vara någon som saknades.</p>
    </div>
)

export default {
    id: 12,
    code: "kam",
    side: "Då",
    nr: "6.2",
    title: "Kam",
    timeline: 96,
    coords: { north: "55.34579", east: "13.02433" },
    content: content,
    symbol: symbol
}

