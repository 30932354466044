import React, { useContext, useEffect } from "react"
import { AppContext } from "../store"
import { CHARS, styleCoord, openNewTab } from "../utils"
import anime from 'animejs/lib/anime.es.js'

import Characters from "./Characters"

export default function Coordinates({ showCoords }) {
    const { state } = useContext(AppContext)

    const north = state.current == -1
        ? ""
        : styleCoord(state.items[state.current].coords.north, "N")

    const east = state.current == -1
        ? ""
        : styleCoord(state.items[state.current].coords.east, "E")

    useEffect(() => {
        anime.timeline()
            .add({
                targets: ".coordinates .ls",
                opacity: [0, 1],
                duration: 1200,
                easing: "easeInOutExpo",
                delay: anime.stagger(120)
            }, 10)
            .add({
                targets: ".coordinates .ls",
                opacity: [0, 1],
                translateY: function(el, i, l) {
                    const isNorth = el.classList.contains("north")
                    const idx = isNorth ? i : i - east.length

                    const from = anime.random(0, (CHARS.length - 1) * 22)
                    const to = isNorth
                        ? CHARS.indexOf(north[idx]) * -22
                        : CHARS.indexOf(east[idx]) * -22

                    return [from, to]
                },
                easing: "easeInOutExpo",
                duration: 1000,
                delay: anime.stagger(120)
            }, 10)
    }, [])

    useEffect(() => {
        if (showCoords) {
            anime({
                targets: ".coordinates",
                opacity: [1, 0],
                duration: 750,
                translateY: [0, -10],
                easing: "easeInOutExpo",
            })
        }
    }, [showCoords])

    return (
        <div className="content-container">
        <div className="coordinates">
            <div className="coordinates-inner" onClick={openNewTab(state)}>
                {north.split("").map((c, i) => (
                    <div key={i} className="ls north">
                        <Characters />
                    </div>
                ))}

                <span className="divider" />

                {east.split("").map((c, i) => (
                    <div key={i} className="ls east">
                        <Characters />
                    </div>
                ))}
            </div>
        </div>
        </div>
    )
}

