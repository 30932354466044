import React, { useRef, useEffect } from "react"
import { useInview } from "../hooks"
import anime from 'animejs/lib/anime.es.js'

export default function Timeline({ fade, card }) {
    const r = useRef()
    const inview = useInview(r)

    useEffect(() => {
        if (inview) {
            anime.timeline()
                .add({
                    targets: ".timeline",
                    easing: "easeInOutExpo",
                    scaleY: fade ? [1, 0] : [0, 1],
                    opacity: fade ? [1, 0] : [0, 1],
                    duration: fade ? 800 : 1000
                }, 0)
                .add({
                    targets: ".timeline .timeline-line",
                    easing: "easeInOutExpo",
                    scaleX: fade ? [1, 0] : [0, 1],
                    opacity: fade ? [1, 0] : [0, 1],
                    duration: fade ? 600 : 1000
                }, fade ? 50 : 200)
                .add({
                    targets: ".timeline .timeline-marker",
                    easing: "easeInOutExpo",
                    opacity: fade ? [1, 0] : [0, 1],
                    left: fade ? [`${card.timeline}%`, "1%"] : ["1%", `${card.timeline}%`],
                    duration: fade ? 500 : 1000
                }, fade ? 100 : 800)
                .add({
                    targets: ".timeline .timeline-circle",
                    easing: "easeInOutExpo",
                    translateY: fade ? [0, -10] : [-10, 0],
                    opacity: fade ? [1, 0] : [0, 1],
                    duration: fade ? 400 : 800
                }, fade ? 200 : 1500)
        }
    }, [fade, inview])
    
    return (
        <div ref={r} className={`timeline ${card.side}`}>
            <div className="timeline-line">
                <div className="timeline-marker">
                    <div className="timeline-circle" />
                </div>
            </div>
        </div>
    )
}
