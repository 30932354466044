import React, { useContext, useEffect } from "react"
import { AppContext } from "../store"
import { CHARS, styleCoord, openNewTab } from "../utils"
import anime from 'animejs/lib/anime.es.js'

import Characters from "./Characters"

export default function HiddenTitle({ delay, showCoords, fade, nav }) {
    const { state } = useContext(AppContext)

    const north = state.current == -1
        ? ""
        : styleCoord(state.items[state.current].coords.north, "N")

    const east = state.current == -1
        ? ""
        : styleCoord(state.items[state.current].coords.east, "E")

    const title = state.current == -1 ? "" : state.items[state.current].title

    useEffect(() => {
        anime.timeline()
            .add({
                targets: ".hidden-title .line",
                opacity: [0.3, 1],
                scaleX: [0, 1],
                easing: "easeInOutExpo",
                duration: 2500
            }, 350 + (delay || 0))
            .add({
                targets: ".hidden-title .ls",
                opacity: [0, 1],
                duration: 1800,
                easing: "easeInOutExpo",
                delay: anime.stagger(250)
            }, 200 + (delay || 0))
            .add({
                targets: ".hidden-title .ls",
                translateY: function(el, i, l) {
                    return [
                        anime.random(0, (CHARS.length - 1) * 24),
                        CHARS.indexOf(title[i]) * - 24
                    ]
                },
                easing: "easeInOutExpo",
                duration: 1500,
                delay: anime.stagger(250)
            }, 10 + (delay || 0))
    }, [])

    useEffect(() => {
        if (showCoords) {
            anime.timeline()
                .add({
                    targets: ".hidden-title .coordinates-small",
                    opacity: [0, 1],
                    easing: "easeInOutExpo",
                    duration: 1000
                }, 0)
                .add({
                    targets: ".hidden-title .back-button",
                    opacity: [0, 1],
                    translateX: [10, 0],
                    easing: "easeInOutExpo",
                    duration: 1000
                }, 250)
        }
    }, [showCoords])

    useEffect(() => {
        if (fade) {
            anime.timeline()
                .add({
                    targets: ".hidden-title .line",
                    easing: "easeInOutExpo",
                    opacity: [1, 0],
                    scaleX: [1, 0],
                    duration: 1200
                }, 0)
                .add({
                    targets: ".hidden-title .ls",
                    easing: "easeInOutExpo",
                    opacity: [1, 0],
                    translateX: [0, -20],
                    duration: 1000,
                }, 250)
                .add({
                    targets: ".hidden-title .coordinates-small",
                    easing: "easeInOutExpo",
                    opacity: [1, 0],
                    translateX: [0, -20],
                    duration: 600,
                }, 250)
                .add({
                    targets: ".hidden-title .back-button",
                    easing: "easeInOutExpo",
                    opacity: [1, 0],
                    translateX: [0, 10],
                    duration: 600,
                }, 100)
        }
    }, [fade])

    return (
        <div className="hidden-title">
            <div className="ls-outer">
                {title.split("").map((w, i) => (
                    <div key={i} className="ls">
                        <Characters />
                    </div>
                ))}
            </div>

            <div className="line" />

            <p className="coordinates-small" onClick={openNewTab(state)}>
                {north} {east}
            </p>

            <button onClick={() => nav("/")} className="back-button">
                <span className="shaft" />
            </button>
        </div>
    )
}
