import React from "react"
import symbol from "../../assets/images/symbol_1.0.png"

const content = () => (
    <div className="content">
        <p>Den natten pojken föddes rusade jag mot förlossningen i en taxi med skallrande fönster och regnet i mörka gardiner utanför. När jag kom in slet de av mig kläderna. De tryckte ner mig i en säng, gav mig en nål i handryggen, en papperskorg att kräkas i och CTG fastklistrat på magen. Jag tyckte mig se mina värkar materialiseras på en grönsvart skärm men sedan svepte smärtan iväg mig.</p>
        <p>Kvart över fyra var han född. Han bajsade sitt svarta kletiga innanmäte över mig och vi hjälptes åt att torka honom, barnmorskan och jag. Han sög och skrek som han skulle. Jag höll honom som jag aldrig hållit något förut: Skräckslaget och översköljt av omsorg. Sköterskorna som tittade till oss sade att allt var bra. Läkaren och barnmorskan sade att allt var bra. Allt var bra. "Lägg honom på din mage," sade de. Jag försökte ringa min mamma som var iväg på jobb utanför Köpenhamn men fick inte tag på henne.</p>
        <p>Jag flyttades till BB-avdelningen och fick en tunn gul bomullsfilt över mig. Utanför fortsatte regnet. Jag ringde Hilda och sade att pojken kommit och hon meddelade moster Rosi. Rosi skickade ett långt mail som jag läste på mobilen. Pojken låg som en gulrosa korv på min mage. På andra sidan min hud nu. Ute. Hans huvud vilade mot mitt bara bröst. Jag förstod inte riktigt det hon skrev. "Nu har du allt lilla älskling", skrev hon. "Nu behöver du aldrig mer längta efter någonting". Jag tyckte att det kom som svallvågor. Jag blundade och såg de döda. De var med mig. Även de döda skickade sina meddelanden. Jag blundade och såg mina kusiner. Jag glömde bort att jag var på sjukhuset, att pojken var född, att jag var ensam. Jag öppnade ögonen och såg en tavla som hängde på väggen framför mig. Den föreställde landskapet jag förstod fanns utanför fönstret. Jag kom ihåg det. Plastbaljan på hjul stod bäddad med en ljusblå handduk bredvid min säng. Sjukhusets nätunderbyxor låg i en hög på nattygsbordet. En flaska mangojuice och en påse nötter som jag trott att jag skulle behöva. Hilda hade sagt att jag skulle ta med mig fina underkläder, och det hade jag gjort, men jag ville inte använda dem.</p>
        <p>Ljuset från mobilen skar som en machete genom det mörka. I natt hugger jag upp en palmdunge vid havet. Jag hade fött en människa. Ja. Men vad skulle ske nu. Ingen aning. Jag tittade på mobilen som om den var en exotisk frukt. Jag var van vid att vi alla använde stora ord och jag var van vid att inte förstå vad som menades, men detta var annorlunda. Sprickan mellan min känsla och det som sades var brutal. Grattis! Bra kämpat! Jag försökte skriva en text som sade tack. Där låg jag med min söndertrasade kropp och en gul korv som bara ville sova nära mig och allt hade skett på bara några timmar. Helt plötsligt, nu, var allting annorlunda. Det var för mycket.</p>
        <p>Ändå nickade jag där jag låg på kudden i sängen, som om alla röster funnits i rummet och kunde tyda mitt huvuds tysta rörelse. Jag ringde till mamma igen. Hon svarade inte. "Hej", sa jag till hennes telefonsvarare. "Bebisen har kommit nu". Det ekade av tystnad när jag lade på. Jag visste att hon inte skulle ringa tillbaka men när sms:et väl kom förstod jag att jag skulle bli överlycklig.</p>
        <p>Efter några dagar kom ett paket till min BB-avdelning. Sköterskan kom med en lapp och jag fick gå till expeditionen för att hämta ut min försändelse. Jag skar upp kartongen med en papperskniv jag fått låna från sjuksköterskornas kontor. Under lysrören i köket ramlade en lapp ut. Det var ett vackert kalligraferat kort, handskrivet på hemgjort papper och med mammas omisskännliga bläck. Det var en dikt. Och dikten slutade med orden: "Du är redskapet som driver spiken in i livets träd", stod det. I paketet låg en röd klänning i organza inslagen i silkespapper.</p>
    </div>
)

export default {
    id: 2,
    code: "sekund",
    side: "Då",
    nr: "1.2",
    title: "Sekund",
    timeline: 16,
    coords: { north: "63.10579", east: "14.38082" },
    content: content,
    symbol: symbol
}

