import React from "react"
import symbol from "../../assets/images/symbol_6.1.png"

const content = () => (
    <div className="content">
        <p>Morgonljuset fick den sandiga skånekusten att lysa. Jag hällde upp välling åt pojken, bröt vitt mjukt bröd. Jag tänkte på rena kläder. Gav pojken vindruvor. Tänkte på rena underkläder. När han druckit ur den första gjorde jag i ordning en flaska till. Jag tittade ut och tänkte på den fuktiga kylan på vintern. Ångrade att jag inte tagit med mössa. När pojken började gnälla av tristess gav jag honom en kula att leka med. Han tystnade fascinerad när den runda sträva formen rullade mellan oss på sätet. När vi närmade oss Lund började plötsligt flingor dala som snö utanför rutan, och det triviala förvandlades till något magiskt. Flingorna kom från ett blomsterträd med en frösläppning jag aldrig sett maken till. Det virvlade in i kupén genom en vädringsspringa i fönstret och landade också över min lilla pojkes ansikte, förändrade det så att det blev nytt och vitskimrande. När jag lutade mig fram doftade han av äpple. Jag såg Pauls svettiga ansikte framför mig så som det sett ut den eftermiddagen då pojken blev till. Sedan såg jag pojkens. Som om Paul gått in i en fotoautomat och när jag vände mig om kom fotot ut och föreställde någon helt annan. Vems ansikte skulle pojken bära? Paul var borta och min egen familj stod redo att sätta sina märken i honom. Jag tittade ut genom fönstret och såg att mina konturer lyste klarröda.</p>
        <p>Vi sade hej då i Malmö för här skulle Wilhelm fara tillbaka.<br />"Jag älskar dig", sade han när han hjälpte mig att lyfta ner vagnen på den gråspräckliga perrongen. Jag tänkte på min syster och nickade. Jag älskade honom tillbaka. Jag såg havet som skimrade i brunt och silver. Ett ringlande glitter bort mot de dammiga fälten. Jag klibbade i handflatorna mot låren. Det rann över brösten. Det doftade ingenting. Ingenting lät. Himlen lyste hög och tom. Det var högtidligt på ett sätt som bara sanning kan vara.</p>
        <p>Utanför Malmö central stannade jag en mattsvart taxibil och bad chauffören köra mig till drömmarnas hus, jag menar Rosengården. När vi svängde upp på gårdsplanen utanför huset stod de på rad för att ta emot oss: Hilda, flickorna och Andras. Jag försökte lägga handen över pojkens mjuka lugg, försökte röra vid pojkens axel. Handen på hans kropp. Framåt, tänkte jag. Titta framåt. Hilda hade krattat räfflor i gruset. Jag tänkte att jag kanske hade råkat glömma allt som var viktigt. Jag hörde att pojken skrattade. Om en liten stund skulle vi kunna promenera till havet. Klipp klopp. Vågorna vätte klapperstranden. Sedan betalade jag chauffören, klickade upp låset, välte ur packningen och klev ur.</p>
    </div>
)

export default {
    id: 11,
    code: "procent",
    side: "Nu",
    nr: "6.1",
    title: "Procent",
    timeline: 88,
    coords: { north: "55.36337", east: "13.00044" },
    content: content,
    symbol: symbol
}

