import React from "react"
import symbol from "../../assets/images/symbol_4.1.png"

const content = () => (
    <div className="content">
        <p>Den första gången jag på allvar började tänka på att ge mig av var i mitten av april. Helgkonferenserna hade satt igång. Borta vid bron såg jag en grön buss komma körande. Jag såg Mona där nere. Hon stod i trädgårdskläder beredd att ta emot dem. Vaktmästarna hade fångat bäver på morgonen. De låg i en hög, tre glänsande kroppar på gräsplätten bakom henne. Det sved i solljuset. Jag såg hur Mona vred på huvudet. Letade efter mig. Jag borde vara där nere med henne.</p>
        <p>Draget från fönstret lade sig som en hatt över pannan. Bussen stannade i gruset. Luften stod mättad. Utkyld och tom. Jag hade flimmer i kroppen av elementvärmen. Pojken kom krypande vid mina fötter. Han var sex månader och märkte allt. Han märkte raggsockornas tjocka garntrådar, de dåligt fästade sluttrådarna. Han märkte jeansens sömmar, elementets kraftfullt böljande järnstruktur och blomkrukorna i svart relief ovanför honom. Han sträckte sig efter allt. Han såg telefonen som jag höll i handen. Han blev otålig. Han ville också ringa. Jag drog fram en stol och lyfte upp honom så att han kunde stå och titta ut. Jag lade mina händer om hans axlar. Han pekade ut genom fönstret, pekade på det glittrande vattnet som rann förbi. Kanske visste han också att jag borde vara där nere vid bussen och ta emot gästerna. "Loo", lät han. "Ja just det bro", sade jag. Jag såg hur konferensgästerna packade ur sina väskor. De hade tur som kunde åka härifrån på söndag. Jag förstod inte hur Mona hade stått ut så länge. Det var som ett frö, men snart hade tanken slagit ut. Under lång tid hade mitt mål varit att ta över efter henne. Nu när jag såg henne stå där nere, vrida på huvudet, kände jag bara hur det sved i halstrakten. Det där ordet "tur", och efter det ordet "kunde". Jag kunde också åka härifrån. Jag kunde också ha tur, med mamma och Hilda och allt det andra. Till min förvåning nickade huvudet: Ja. Det gjorde mig glad. Svedjan i halsen lättade.</p>
    </div>
)

export default {
    id: 8,
    code: "riset",
    side: "Då",
    nr: "4.2",
    title: "Riset",
    timeline: 64,
    coords: { north: "63.01352", east: "14.02354" },
    content: content,
    symbol: symbol
}

