import React from "react"
import symbol from "../../assets/images/symbol_3.1.png"

const content = () => (
    <div className="content">
        <p>På Stockholm Central, där nattmolnen fladdrade som sparvar över sjösystemets förgreningar, överraskade jag mig själv med att fråga tågvärden om han ville komma med in i min kupé.<br />"Som att fortsätta resan ihop", frågade han.</p>
        <p>Det var något med hur han stod med sina rundade benen och magen. Jag tyckte för ett ögonblick att han liknade Paul. Den grovmaskiga stålkonstruktionen bakom honom höll andan och jag visste att min fråga var vågad. Jag såg skuggan som gick genom hans kropp. Men var den av mässing eller silver? Det såg jag inte. Jag nickade. Ja. Som att fortsätta ihop.<br />"Jag har jordnötter", sade jag och visade påsen. Skuggan försvann och tågvärden liknade ingen annan än sig själv. Men hans blick var kvar under håret och jag kunde inte värja mig.<br />"Och så har jag apelsiner", sade jag. "Pojken ska sova nu".<br />Jag visade med händerna hur han låg och sov med kinden vilande mot handryggen. Tågvärden drog grunt efter andan. Han nickade. Ja, det gick bra. Han slutade nu och det var inget som förbjöd att han kom till min kupé. Vi såg på varandra och nickade och jag pekade på pojken och sade hans namn. "Karl". Det kändes högtidligt att säga min pojkes namn till någon okänd. Karl. Nu fanns även han i den här nya märkliga världen.<br />"Wilhelm", sade konduktören.</p>
        <p>Utanför gled mörklagda huskroppar förbi i rytmiskt böljande dyningar. Det var långt kvar till Skåne. Det kommer att gå bra. Vagnen lystes upp då Wilhelm släppte ut sitt låga skratt. Han visade hur han krympte utrymmet mellan tummen och resten av fingrarna. Tyst. Jag nickade. Vi ska prata tyst och det ska vara trevligt. Nitrasgården var också långt borta nu, för tillfället helt frånvarande. Jag kände hur mitt ansikte sprack upp som på en lycklig katt när Wilhelm drog fram en flaska gin ur innerfickan.</p>
    </div>
)

export default {
    id: 5,
    code: "vatten",
    side: "Nu",
    nr: "3.1",
    title: "Vatten",
    timeline: 40,
    coords: { north: "59.19510", east: "18.03286" },
    content: content,
    symbol: symbol
}

