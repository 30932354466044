import React, { useState, useContext, useEffect } from "react"
import { AppContext } from "../store"
import anime from 'animejs/lib/anime.es.js'

import Timeline from "./Timeline"
import BackButton from "./BackButton"

export default function ContentContainer({ fade, nav }) {
    const { state } = useContext(AppContext)
    const [show, setShow] = useState(false)
    const [showArrow, setShowArrow] = useState(true)
    const current = state.items[state.current]
    const Content = current.content

    useEffect(() => {
        anime({
            targets: ".content-inner .continue",
            easing: "easeInOutExpo",
            opacity: [0, 1],
            translateY: [-20, 0],
            duration: 600
        })
    }, [])

    useEffect(() => {
        if (show) {
            anime({
                targets: ".content-inner .content",
                easing: "easeInOutExpo",
                opacity: [0, 1],
                translateY: [10, 0],
                duration: 1200
            })
        }
    }, [show])

    useEffect(() => {
        if (fade && showArrow) {
            anime.timeline()
                .add({
                    targets: ".content-inner .continue",
                    easing: "easeInOutExpo",
                    opacity: [1, 0],
                    translateY: [0, -20],
                    duration: 600
                }, 0)
                .add({
                    targets: ".content-inner .symbol",
                    easing: "easeInOutExpo",
                    opacity: [1, 0],
                    duration: 600
                }, 0)
        }

        if (fade && show) {
            anime.timeline()
                .add({
                    targets: ".content-inner .content",
                    easing: "easeInOutExpo",
                    opacity: [1, 0],
                    translateY: [0, 10],
                    duration: 800
                })
                .add({
                    targets: ".content-inner .symbol",
                    easing: "easeInOutExpo",
                    opacity: [1, 0],
                    duration: 600
                }, 0)
        }
    }, [fade])

    setTimeout(() => {
        setShow(true)
        setShowArrow(false)
    }, 200)

    const imageOnLoad = () => {
        anime({
            targets: ".content-inner .symbol",
            easing: "easeInOutExpo",
            opacity: [0, 1],
            duration: 600
        })
    }

    return (
        <div className={show ? "content-container block" : "content-container"}>
            <div className="content-inner">
                <div className="symbol">
                    <img src={current.symbol} onLoad={imageOnLoad} />
                </div>

                <Content />
                <Timeline card={current} fade={fade} />
                <BackButton fade={fade} nav={nav} />
            </div>
        </div>
    )
}
