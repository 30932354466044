import React from "react"
import symbol from "../../assets/images/symbol_3.1.png"

const content = () => (
    <div className="content">
        <p>Efter några dagar hemma lärde jag mig att jag kunde sitta vid mitt arbetsbord i salvkokeriet, med ryggen böjd över ett recept, ungefär som jag brukade göra. Det var en kopia av en handskriven folie från 1035 med rölleka. Dammet kunde som vanligt sväva i en stråle av blek sol. Jag kunde läsa receptet till slut. Amma. Sedan plocka ihop ingredienser och skålar. Amma. Sedan ta en cigarett på den vinterkalla stentrappan. Amma. Sedan hänga av mig halsduken, sätta på mig förklädet och sätta igång. Så länge pojken var liten kunde han ligga på en fäll på golvet nedanför mitt arbetsbord och min arbetsrytm kunde fortsätta. Arbeta, amma. Tänka, amma. Jag kunde vända ryggen till det andra. Så hade min mamma lärt mig. Prioritera, inte sådant som hade med mig, utan sådant som hade med henne att göra. I sammanhang som behövde hennes kunskaper, hennes begåvning och engagemang och där jag och Hilda störde henne minimalt.</p>
        <p>När mamma kom hem från jobben tyckte jag att hon var härlig i sin sobra vardagsklänning och pärlorna runt halsen. Arbetsansiktet påsatt med läppstift och concealer. Hennes böcker och anteckningar nedstoppade i en väska med rem. Jag ville bli som hon: Eftertraktad, exklusiv, bäst på det hon gjorde. Men jag hade också varit hennes barn. Så fort hon gått längtade jag bara efter att hon skulle komma hem och vara där. "Borsta tänderna. Kamma håret. Kom ska vi läsa din bok."</p>
        <p>När pojken började krypa hackades vår rytm itu. Plötsligt var allt inom räckhåll för honom och det smittade av sig. Som en trasig maskin öppnade sig dörrar även för mig. Möjligheter. Erbjudanden. Synkronicitet. Moderhuvudet sade med sin barska stämma: Glad! Nu måste du vara glad! Och jag, som förknippat glad med mitt arbete, tänkte att det skulle ha med det att göra. Arbeta mer eller på ett annat sätt. Samtidigt såg jag pojken dra ner sticklingar i golvet. Suga i sig klumpar av jordig växtnäring från golvet. Rulla runt och hamna i hörnet för hackor och spadar. Jag drömde att Mona kom springande och ropade om omen: "Alla plantor från den första sådden har fröat av sig. Växthuset är fyllt av sticklingar som behöver planteras!" Ge upp! Förflytta dig! Allt runtomkring mig skrek.</p>
        <p>Jag packade upp mammas rubinröda klänning. Den hängde på tvättlinan utanför huset. Gungade fram och tillbaka i blåsten. Jag tänkte att jag skulle ha den där ute hela våren, låta den blekas. Klänningen skulle anta alrunans form och därmed befria mig.</p>
        <p>Då ringde Hilda, en dörr flög upp, och bakom henne stod hela hennes gård och utanför den Malmö, havet, Europa och längre bort än så.<br />"Jag ska börja med snittblommor", sade hon. "Jag ska utöka försäljningen med sådant som luktar gott och sådant som är vackert. Mamma vill också att du kommer", så sade hon i telefonen.</p>
        <p>Jag tyckte att jag såg dem, vinkade åt mig att komma. Även fast jag inte trodde henne – ville mamma att jag kom till henne - trodde jag att det betydde något särskilt att Hilda ljög om just det här. Jag gick ut i trädgården och plockade ner klänningen. Hilda hade en plan för mig och pojken och jag kände mig intresserad av att veta mer. Så var det. Jag ville säga ja men jag vågade inte. Hela magen började krypa och galoppera. Jag packade ner klänningen i kartongen. Jag lade in halsbandet i klänningens silkespapper. Pauls halsband. Det låg invirat i samma material som klänningen legat i. Jag lade halsbandet i innerfickan på skinnjackan och klänningen i den resväska jag skulle ha med mig. Det kändes tryggt och lite otäckt att ha det så nära.</p>
    </div>
)

export default {
    id: 6,
    code: "kläder",
    side: "Då",
    nr: "3.2",
    title: "Kläder",
    timeline: 48,
    coords: { north: "63.01352", east: "14.02354" },
    content: content,
    symbol: symbol
}

