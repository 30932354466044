import React from "react"
import symbol from "../../assets/images/symbol_1.0.png"

const content = () => (
    <div className="content">
        <p>Fjällets sluttningar bolmade av grönska den dagen jag och min lilla pojke lämnade Nitrasgårdens folkhögskola. Sommarastern i rabatten var full av fjärilar. Jag klädde oss i bomull, ljus och lätt, som gjorde oss till fladdrande vålnader mot de tunga plattorna i terrassen. Sandaler. En sjal över axlarna. Pojken med keps. Kroppen insvept i vit tunn pyjamas.</p>
        <p>Mona körde oss. Hon hade pressad linnekostym i beige och solglasögon. Hon älskade sin isop och kryddväxterna i örtagården mer än allt annat och kunde lätt, nästan lättsamt, skjutsa iväg oss från skolan. Jag satte pojken i hans bilbarnstol och öppnade en påse kardemummakakor. Det doftade gott och när jag tittade på pojken tyckte jag att han log.<br />"Sitter han fast med bältet".<br />Mona vände sig om innan hon lade i växeln och backade ut. Jag bet en bit av kakan.</p>
        <p>När jag öppnade bildörren vid busstationen i Oviken doftade det av spannmål och stål och himlen var obehagligt roströd.<br />"Säljer du den här åt mig då," frågade jag och pekade mot barnstolen som satt kvar i bilen.<br />Mona nickade. Inga problem. Hon hjälpte mig att packa ur bakluckan. Barnvagn, ryggsäck, skötväska, resväska. Sedan stod hon nedanför och väntade medan jag steg upp på bussen. Den pyste förtroendeingivande. Jag fick upp allt på bagagehyllan. Jag såg henne genom fönsterrutan. Hon tog ett steg tillbaka. När vi rullade iväg vinkade hon adjö med en noppvirkad näsduk. Just så hade mamma också kunnat göra.</p>
        <p>Bussen körde med hög hastighet rakt in i det plottriga landskapet. Jag lade äpplen och eftermiddagskex på det nedfällbara plastbordet. Jag öppnade en tetra välling och hällde över i nappflaska. Pojken sträckte sig efter den. Tog den i sina små runda händer. Lutade sig tillbaka. Jag såg på honom. Han var så overklig. Som en bebis på en bild från någon tidning. Inte riktigt mänsklig. Nio månader. Lika mycket utanför som inuti. Han såg ut som en riktig person med framtänder i underkäken och lugg. Främmande och välbekant på samma gång. Som en bild på en pojke man kunde ha vid sin sida.</p>
        <p>När jag blev gravid och bestämde mig för att jag ville behålla pojken trodde jag att det skulle bli sista gången jag lät något känslostyrt vara avgörande. Men här satt jag, igen, med siktet inställt på framtiden. Det skulle kunna bli dumt och helt fel, men det hade varit viktigt, kanske nödvändigt, att jag lämnade Nitras. Hela våren hade jag drömt mardrömmar och när jag vaknade var det som om natten hade målat sovrummet med vattenfärg. På väggarna tyckte jag att det stod ord som handlade om att knyta an till familjen och min historia bakåt. Ibland i brandrött, varnande, ordet "allein", ensam. Jag vet inte varför det stod på tyska, men jag förstod att det var rätt ord och när jag läste det ville jag inte längre vara så, ensam. Så som jag kände mig.</p>
        <p>När vi växte upp stod jag och min syster Hilda varandra mycket nära. Kanske för att mamma var som hon var. När vi blev äldre blev Hilda den med energi. Den som längtade ut. Hon utbildade sig, träffade Andras, startade företag och fick barn. Hennes liv tog form. Under tiden stod jag still. Jag satt i fönstret i mammas lägenhet som om jag väntade på min tur. När Hildas tvillingarna fötts var det som om någon tryckt om knapparna. På mig stod det start. Jag tog över hennes cigarettpaket, hennes skinnjacka och rörliga beteende. Och jag for. Först till Italien för att utbilda mig inom örtodling och botanik, och sedan till Tyskland för att gå i skola i samma kloster som Hildegaard af Bingen. När jag kom till Nitras var det för att hålla en tillfällig kurs om dofter från trädgården men kände ganska snart att jag hade lust att stanna. Det var något med Mona. Hon – intresserade mig. Jag förstod inte med en gång att det var för att Mona påminde om mamma. Men jag såg framför mig att jag en dag skulle ta över stället.</p>
        <p>När jag ringde Hilda för att säga att jag blivit gravid blev hon först lycklig så där som man hade kunnat tänka sig, men sedan arg när jag berättade att jag tänkte föda barnet själv.<br />"Du kommer att bli som mamma", sade hon. "Har du glömt hur det var att vara hennes barn".<br />Under hela graviditeten bråkade vi. Långa utdragna mail med svek och varningar. Men när pojken sedan föddes förändrades det. Det var som att vi båda två påmindes om att vi borde höra ihop.<br />Hilda bodde i Malmö med tvillingarna och Andras arbetspendlade.<br />"Ibland känner jag mig ensam," sade hon. "Och ibland behöver jag hjälp".<br />Det började med att vi skickade bilder på våra barn på mobilerna. Sedan började vi skicka text. Efter ett tag kom budgetuträkningar och instruktioner för hur man öppnar verksamhet. När pojken var två månader ringde vi varandra nästan varje dag. Det där "allein" kändes inte längre akut.</p>
        <p>Från bussen såg jag att granarna stod som grova strån i en hårborste och fjällen knycklade horisonten med en silhuett i grått och svart. Jag tyckte att gräsplättarna lyste onaturligt och den röda himlen olycksbådande. Pojken åt sina kex och äpplet som jag skurit i små bitar. Han pillade på sömmarna på sina byxor och slickade på gummilisten som tätade bussfönstret. Då kom åskan. De första blixtarna skar himlen i tre stora bitar. Jag räknade en, två, tre. Jag, Hilda och mamma. Pojken blev rädd av braket men jag lugnade honom med min mjukaste röst samtidigt som jag påminde mig själv om att jag tillhörde de mäktiga.</p>
    </div>
)

export default {
    id: 1,
    code: "sömn",
    side: "Nu",
    nr: "1.1",
    title: "Sömn",
    timeline: 8,
    coords: { north: "62.59479", east: "14.23407" },
    content: content,
    symbol: symbol
}
