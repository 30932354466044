export const CHARS = "abcdefghijklmnopqrstuvwxyzåäöABCDEFGHIJKLMNOPQRSTUVWXYZÅÄÖ0123456789!#%&/()=?.°`’”"

export function styleCoord(c, label = "N") {
    const p1 = `${c.slice(0, 2)}°`
    const p2 = `${c.slice(3, 5)}’`
    const p3 = `${c.slice(5, 7)}.`
    const p4 = `${c.slice(7)}${label}`
    return p1 + p2 + p3 + p4
}

export function openNewTab(state) {
    const north = state.current == -1
        ? ""
        : state.items[state.current].coords.north

    const east = state.current == -1
        ? ""
        : state.items[state.current].coords.east

    if (north == "" || east == "") {
        return () => false
    }

    const q = `${north},${east}`

    return () => window.open(`https://maps.google.com/?q=${q}`, "_blank")
}
