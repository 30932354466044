import React from "react"
import symbol from "../../assets/images/symbol_2.1.png"

const content = () => (
    <div className="content">
        <p>När bussen kom fram till Östersund och vi bytte till tåg hade åskvädret blivit till rekvisita. Jag höll pojken i handen när han singlade omkring som en tunn, gyllene frökapsel över den nattblå heltäckningsmattan. När han blev trött gick vi tillbaka till kupén och jag plockade fram leksakstenar i gummi som jag placerade ut över golvet för pojken att krypa över.</p>
        <p>Efter någon timme kom en tågvärd. Det hade hunnit bli skymning och han läste med svårighet våra namn och platsbiljetter. Jag såg hur trött han var. Han hade fåror vid tinningarna och jag tyckte att kinderna stramade över käkbenet. Ändå frågade jag honom var vi var. Han tog fram en skrynklig karta från fickan i sin styva, solrosgula skjorta. Hans torra trötta finger pekade ut en prick på kartan. "Där", sade han och såg på mig. Granskade mig kanske. Min ljusa sommarkjol; kändes den inte lite väl vippig. Sandaler! Vem trodde jag att jag var? (Den där ensamma kvinnan med barn) "Bara där". Jag tittade på hans finger och försökte flytta punkten på kartan till det åskiga landskapet utanför fönstret. Han tog stöd mot väggen när jag lutade mig mot fönstret. "Eller här," sade han igen och pekade utåt i det grå.</p>
        <p>När jag fick min lilla pojke förvreds tidens och rummets betydelse. Det var inte längre "i köket där vi lagar mat" eller "i växthuset tar vi hand om våra odlingar". Överallt skulle jag i stället amma. På varje plats där jag tidigare arbetat skulle pojken ta bröstvårtan. Jag skulle öppna min skjorta, vika ner bh:n, täcka mitt (eller hans) ansikte för skammen. Överallt skulle vi markera vår närvaro. Jag behövde lägga ut hans filt. Jag behövde spänna upp hans babygym. Ibland behövde jag torka uppkräkt bröstmjölk från golvet i klassrummet. Vi släpade runt på vår närvaro. Hela tiden tillsammans. Hela tiden insnärjda i vår speciella plats. Jag skulle vara pigg och vaken klockan tre på morgonen och sedan sova klockan åtta. Jag skulle vara tillgänglig dygnet runt och anpassa min egen tid till pojkens. Efter ett tag kände jag mig som en magisk rot, en förvriden alruna, som dragits ur jorden och nu väntade på – ja inte döden kanske men något som liknande döden. Ett slags icketillstånd där min egen andning uppehölls av kaffe och lösviktsgodis. Artificiella maskiner och instrument.</p>
        <p>Pojken tuggade sig igenom tillvaron och jag vakade över honom. Roten förvandlad till ögon, öron, känsel, lukt. Han sög sig igenom dagarna och jag vaktade. Jag kraxade, gläfste i utdragna ljud. Jag kastade mig ur sängen för jag var instängd och försökte fly, mot gallret för jag var snärjd i en bur av imiterad frihet. Någon gång tyckte att jag hörde en vag stämma inuti huvudet som sade att kärleken till pojken skulle befria mig. Men jag hade ingen känsla av att det skedde.</p>
        <p>Jag och Paul, som var pojkens biologiska pappa, hade lånat en skäck som vi ridit på genom den tufsiga fjällbjörkskogen. Vi hade haft en första lyckad träff, sedan en lite mindre lyckad men jag ville ändå träffa honom igen. Det var jag som föreslagit ridutflykt. Jag var inte kär. Det kunde jag nog ha blivit om det inte hade blivit som det blev med pojken. Ofta kände jag mig särskilt närvarande utomhus, när jag kunde vara i landskapet. Och det var som att den här mannen – jag strök med handen över hästens bog, kände på värmen, styrkan och muskelns rörelse. Det blåste men hästen och Paul var varma. Vi klev av hästen och lade oss på mage på en flat sten. Temperaturen föll och vi visste att det skulle börja regna. När vinden ökade i styrka visade jag honom var vi kunde söka skydd inne i en koja och väl där klädde vi av varandra. Det var inte så att jag aldrig hade sett en naken man förut, men det var något med Paul som var särskilt. Det var det jag tänkte på efteråt i alla fall. Jag hade somnat och när jag vaknade var jag ensam. Regnet hade upphört och skäcken stod inuti kojan och tittade på mig. När jag skulle plocka ihop mina saker hittade jag silverkedjan. Han måste ha tappat den när han klädda av sig. Jag tog kedjan och stoppade den i innerfickan. Sedan satt jag upp. Jag skänklade iväg och tänkte att Paul hade en särskild förmåga, som svartkonst ungefär, att fånga mig precis där jag ville bli fångad.</p>
        <p>Genom tågets regnrandiga fönster såg jag ovädret som åt sig framåt och förbi oss. Fram och tillbaka över den mörkgröna skogens horisont. "Snart är det i Skåne", tänkte jag, och försökte skaka bort den krypande rädslan. Jag skulle velat köpa hästen, även fast den inte hade kunnat ta mig till Rosengården. Det hade hjälpt till i flytten från norr till söder om jag hade ägt en skäck, tänkte jag. Istället hade jag inte tagit med mig någonting. Inte ett knippe slokande fjällsippa, inte en buske hjortron, inte kvanne att smörja på köttet. Jag hade bestämt mig, jag ville inte längre vara fångad i den förvrida rotens levnadssystem. Men det var svårt. För att det var svårt att vara förälder. Och det var svårt, för att jag inte visste hur det skulle fortsätta.</p>
        <p>Jag tog fram mammas klänning och vek upp silkespapperet där jag hade kedjan. Jag skulle kunna byta om. (Vem trodde jag att jag då skulle bli? Den där ensamma uppklädda kvinnan med barn.) När jag vecklade ut klänningen tyckte jag att det rasslade om den som från en skallerorm. Utanför fönstret ljusnade det lika plötsligt som det mörknat. Pojken satt bredvid mig med ansiktet som en glipa av skimrande guld inklämd bland de mörka molnen.</p>
    </div>
)

export default {
    id: 3,
    code: "vänner",
    side: "Nu",
    nr: "2.1",
    title: "Vänner",
    timeline: 24,
    coords: { north: "62.45007", east: "15.25017" },
    content: content,
    symbol: symbol
}

