import React from "react"
import { useDelayedNavigate } from "../hooks"

import Title from "./Title"
import CodeInput from "./CodeInput"
import Footer from "./Footer"

export default function CodeScreen() {
    const [fade, nav] = useDelayedNavigate(1200)

    return (
        <div className="code-screen">
            <Title
                title="Vems ansikte är det?"
                subTitle="En multinarrativ berättelse"
                fade={fade} />
            <CodeInput
                fade={fade}
                nav={nav} />
            <Footer
                fade={fade}
                hidden={true} />
        </div>
    )
}
