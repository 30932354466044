import React, { useState, useEffect } from "react"
import { useDelayedNavigate } from "../hooks"

import HiddenTitle from "./HiddenTitle"
import ContentContainer from "./ContentContainer"
import Coordinates from "./Coordinates"
import Footer from "./Footer"

export default function ContentScreen() {
    const [fade, nav] = useDelayedNavigate(1200)
    const [showCoords, setShowCoords] = useState(false)
    const [showContent, setShowContent] = useState(false)

    useEffect(() => {

        setTimeout(() => {
            setShowCoords(true)
            setTimeout(() => {
                setShowContent(true)
            }, 800)
        }, 3800)
    }, [])
    
    return (
        <div className="content-screen">
            <HiddenTitle
                delay={800}
                fade={fade}
                nav={nav}
                showCoords={showCoords} />

            {showContent
                ? <ContentContainer fade={fade} nav={nav} />
                : <Coordinates showCoords={showCoords} />}

            <Footer
                fade={fade}
                showCoords={showCoords}
                delay={1000} />
        </div>
    )
}
