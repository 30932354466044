import React from "react"
import symbol from "../../assets/images/symbol_5.1.png"

const content = () => (
    <div className="content">
        <p>Jag och Wilhelm stod precis utanför min kupé, med ryggarna lutade mot fönstret och mörkret flimrande där ute. Den flortunna gardinen var fråndragen och jag såg pojken genom glasrutan, nu utfläkt som en ljusgrön havsstjärna över lakanet. Jag satte fingertoppen mot glasrutan. Jag ville knacka så att pojken skulle vakna och se mig. Jag ville att han skulle se hur glad jag var. Men så hårdnade vinden och snabbt mörknade även takten. Det var spriten som talade från sin diamantsvarta plats. Det var ju bra så länge pojken sov. Så länge pojken sov kunde jag stå här med Wilhelms arm tätt intill min egen. Hans andetag precis bredvid mitt. Jag tyckte jag hörde hans hjärta och lutade mig fram för att höra om det var sant. Den hade hänt mig förut, den här tilliten, men det var första gången den hände sedan jag fick pojken. Jag kändes Wilhelms hårda knä genom byxtyget. Höften som en kam när jag böjde mig fram och jag visste att Wilhelm, allt som var med honom, bara skulle vara för i kväll. Är varje möte ett nytt fönster? Ja. Så var det. Det här var början. Men början, vi visste det, den skulle öppnas igen mot ett nytt slut. Jag visste att han visste det också fast han visste det med andra erfarenheter. Det här tåget var slutet för nu i alla fall och i alla fall för oss. Varje kväll skulle inte vara som den här och ingen av oss ville sova.</p>
        <p>Framför oss täcktes landskapet av ett grått skum. Allt verkade vara som vanligt fastän allt var förändrat. Jag ville gå till restaurangvagnen men Wilhelm tvekade.<br />"Det går bra", sade jag. Jag visade honom babytalkapparaten jag hade i min ficka. Pekade på den andra delen som jag ställt på den inbyggda hyllan ovanför pojkens huvud. Och Wilhelm veknade. Nickade. Ok, vi kunde lämna pojken och gå och roa oss.</p>
        <p>När vi kom fram till restaurangvagnen hängde vi våra jackor på krokar intill fönstret som om vi aldrig gjort annat och gled in i de smaragdgröna båsen. Vi beställde öl och nötter. Drack tysta i korta nervösa klunkar.<br />"Känns det inte farligt att lämna honom så där", sade Wilhelm efter en stund.<br />"Han har ju babytalken", sade jag. "Jag kommer att höra honom om han gråter."<br />Wilhelm lyfte handen och strök mig över kinden. En helt öppen handflata mot min kind. Jag såg hastigt framför mig hur en jättelik fågelpindel kastade sig mot mina mjukaste kroppsdelar och allt – verkligen allt – täcktes av blod och fostervatten och ett olycksaligt rassel. Han släppte mig. Hans värme på min kind. Frånvaron av handen.</p>
        <p>När vi var små brukade mamma alltid säga att det var skogsväsen som kommit med oss.<br />"Varför har vi ingen pappa" frågade vi vissa kvällar när hennes ansikte var mer utslätat och vi såg att hon inte hade pratat hela dagen, inte hade behövt tänka och vara till nytta.<br />"Man behöver ingen pappa" svarade hon. "Ni kom från skogen, som trollpresenter" sade hon. "Ni var allt jag önskade mig och så kom ni, så enkelt är det".<br />"Men varför kom vi inte på samma gång" frågade jag. "Varför kom Hilda först och jag sedan". Mamma tog en rättika och skivade den i tunna stavar mot den grova träskivan i köksbordet.<br />"För att jag önskade er på det sättet" svarade hon. "Först ville jag ha en liten Hildi och sedan en liten Sofie". Sedan mosade hon kikärtor och tog fram bröd som om hon aldrig hade gjort annat än lagat middag och sade åt oss att det var dags att tvätta händerna och komma och sätta oss.</p>
        <p>Då började pojken gråta. Jag hörde hans gråt i babytalken som låg i kavajfickan som hängde på kroken intill fönstret. Först ville jag inte förstå att det var gråten som lät. Först ville jag att det skulle vara ett gnissel från hjulen eller tjut från lattemaskinen. Trots att jag var rädd ville jag bara sitta där med Wilhelms hand igen på min kind. Jag ville känna hur han strök mig över axeln, igen, lätt. Att han gjorde vad han kunde för att undvika mitt hjärta.</p>
        <p>Jag hade inte tänkt på sex på väldigt länge, inte haft det heller. Sedan allt hände med pojkens pappa hade jag lagt den delen av mig själv i en stor kista som jag inte haft en tanke på att öppna. Jag skämdes för att det blivit som det blivit, att jag inte förstått att jag skulle kunna bli gravid, att jag njutit så mycket av att ligga med honom där uppe vid trädgränsen men också alla andra som jag träffat och njutit av för att sedan lämna eller glömma. Spindeln satt på min axel och jag kände att jag skakade. Nu skulle jag få betala. Wilhelm tog sin keps och satte den på mitt huvud. Han böjde sig fram och kysste mig. Hans hand gick innanför min jacka och lade sig prefekt om mitt ena bröst. Han höjde axlarna en smula, slog ut med händerna. Jag nickade. Ok då. Han skulle få följa med mig tillbaka.</p>
    </div>
)

export default {
    id: 9,
    code: "blind",
    side: "Nu",
    nr: "5.1",
    title: "Blind",
    timeline: 72,
    coords: { north: "56.39116", east: "14.15317" },
    content: content,
    symbol: symbol
}

