import React from "react"
import symbol from "../../assets/images/symbol_2.1.png"

const content = () => (
    <div className="content">
        <p>Vi kom hem från BB. Pojken hade funnits i tre dagar. Jag låg ensam i min obäddade säng. Klockan var kvart i tre. Det var natt. Jag tänkte att allt det här nya handlade om underkastelse. Att moderskapet i sin helhet handlade om att jag var tvungen att anpassa mig efter honom. Jag ammade honom varannan timme. Varannan timme vaknade jag i en pöl av mjölk. Det var antingen vätan eller pojken som väckte mig. Jag bytte sida. Ammade. Jag somnade. Han väckte mig. Sängen var blöt. Jag bytte sida, ammade. Somnade. Jag vaknade. Nattlinnet var blött. Jag bad om att få slippa undan. Jag lade upp pojken på en kudde för att inte behöva vända mig hela tiden. Jag bad om att få slippa verkligheten. Jag bad om att någon skulle göra något. Spelar ingen roll vad. Vad som helst skulle gå bra. Men stämman i huvudet reste sig stark och sade: Du måste böja dig.</p>
    </div>
)

export default {
    id: 4,
    code: "flykt",
    side: "Då",
    nr: "2.2",
    title: "Flykt",
    timeline: 32,
    coords: { north: "63.01352", east: "14.02354" },
    content: content,
    symbol: symbol
}

