import React, { useState, useLayoutEffect } from "react"
import { navigate } from "hookrouter"

export function useDelayedNavigate(ms) {
    const [navigating, setNavigating] = useState(false)

    return [
        navigating,
        path => {
            setNavigating(true)
            setTimeout(() => navigate(path), ms || 1000)
        }
    ]
}

export function useInview(ref, ms = 100) {
    const [inview, setInview] = useState(false)

    if (!ref) {
        throw new Error("Required parameter `ref` not supplied to `useInview`")
    }

    useLayoutEffect(() => {
        let timeout = null

        const handleScroll = e => {
            if (inview || timeout !== null) {
                return
            }

            timeout = setTimeout(() => {
                if (ref.current) {
                    const pos = ref.current.getBoundingClientRect()

                    if (pos.top < window.innerHeight && pos.bottom >= 0) {
                        setInview(true)
                    }
                }

                timeout = null
            }, ms)
        }

        window.addEventListener("scroll", handleScroll)
        return () => window.removeEventListener("scroll", handleScroll)
    }, [inview])

    return inview
}
