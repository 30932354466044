import React, { useEffect } from "react"
import anime from 'animejs/lib/anime.es.js'

export default function Title({ title, subTitle, fade }) {
    useEffect(() => {
        const line = {
            targets: ".title .line",
            easing: "easeInOutExpo",
            opacity: fade ? [1, 0] : [0, 1],
            scaleX: fade ? [1, 0] : [0, 1],
            duration: fade ? 1200 : 1500
        }

        const h1 = {
            targets: ".title h1",
            easing: "easeInOutExpo",
            opacity: fade ? [1, 0] : [0, 1],
            translateX: fade ? [0, -20] : [-20, 0],
            duration: fade ? 1000 : 1200
        }

        const h2 = {
            targets: ".title h2",
            easing: "easeInOutExpo",
            opacity: fade ? [1, 0] : [0, 1],
            translateX: fade ? [0, -20] : [-20, 0],
            duration: fade ? 600 : 1000
        }

        anime.timeline()
            .add(line, 0)
            .add(h1, fade ? 250 : 400)
            .add(h2, fade ? 250 : 1000)
    }, [fade])

    return (
        <div className="title">
            <h1>{title}</h1>
            <div className="line" />
            <h2>{subTitle}</h2>
        </div>
    )
}
