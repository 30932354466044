import React, { useReducer } from "react"
import ReactDOM from "react-dom"
import { useRoutes, useRedirect } from "hookrouter"
import { AppContext, reducer, initialState } from "./store"
import CodeScreen from "./components/CodeScreen"
import ContentScreen from "./components/ContentScreen"
import "../assets/css/index.less"

const ROUTES = {
    "/": () => <CodeScreen />,
    "/content": () => <ContentScreen />
}

function App() {
    const [state, dispatch] = useReducer(reducer, initialState)
    const match = useRoutes(ROUTES)

    if (state.current == -1) {
        useRedirect("/content", "/")
    }

    return (
        <AppContext.Provider value={{ state, dispatch }}>
            {match || <CodeScreen />}
        </AppContext.Provider>
    )
}

ReactDOM.render(
    <App />,
    document.getElementById("mount")
)
