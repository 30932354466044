import t11 from "./t11.js"
import t12 from "./t12.js"
import t21 from "./t21.js"
import t22 from "./t22.js"
import t31 from "./t31.js"
import t32 from "./t32.js"
import t41 from "./t41.js"
import t42 from "./t42.js"
import t51 from "./t51.js"
import t52 from "./t52.js"
import t61 from "./t61.js"
import t62 from "./t62.js"

export {
    t11,
    t12,
    t21,
    t22,
    t31,
    t32,
    t41,
    t42,
    t51,
    t52,
    t61,
    t62
}

export default [
    t11,
    t12,
    t21,
    t22,
    t31,
    t32,
    t41,
    t42,
    t51,
    t52,
    t61,
    t62
]
