import React from "react"
import symbol from "../../assets/images/symbol_5.1.png"

const content = () => (
    <div className="content">
        <p>I takt med att våren gick, slog min längtan bort från Nitrasgården ut som en blomma. Jag blev mer förvirrad, och trött av att hela tiden vara vaksam på pojken och trött av att aldrig riktigt få sova. Men jag höll det instängt inom mig. Det var naturligtvis självklart att jag vid något tillfälle skulle explodera, men jag hade inte vetat att det skulle hända och inte heller att det kunde hända. En kväll när jag stod i växthuset, just i färd med att packa ihop mina saker för dagen hände det. Det kom som ett knytnävsslag. Ena stunden stod jag upprätt, med pojken fastsatt i babybjörnen redo att gå hem, och andra stunden låg jag på cementgolvet. Hopkurad, skrikande rakt upp i himlen, med bröstkorgen överfylld av ångest och utmattning. Det slog inom mig som en gigantisk havsvåg, en sådan som slår sönder byar och dödar alla människor. Alla plantorna skrek också. De stod som en mur omkring mig och ropade: Rädda dig själv, rädda dig själv. Och mitt i det skriket framträdde Hildas ansikte som en ängel. Hon trängde sig genom muren och allt jag kunde göra var att viska "ja, just det, ja just det så är det, Hilda, Hilda, Hilda".</p>
        <p>Den våren planterade Hilda sextiofem Nozomi, en klättrande miniatyrros med låga bågböjda grenar, små taggar och skirt ljusrosa blommor. Hon ville att den skulle täcka hela gräsmattan, varenda sänka och förhöjning skulle flöda över av det blomstrande.<br />"Jag vill gå ut på morgonen", sade hon "och inte veta var jag ska gå för att det växer blommor överallt. Jag vill inte överraskas av några stycken. Jag vill att gården ska svämma över".</p>
        <p>Hon planterade dem själv. Grävde etthundrafemtio hål och lade barrotade plantor i dem. Hon grävde hålen i ojämna rader för att kunna klippa av någon ros då och då, men överdrivet många så att de säkert skulle överumpla henne när hon kom ut.</p>
    </div>
)

export default {
    id: 10,
    code: "smörgås",
    side: "Då",
    nr: "5.2",
    title: "Smörgås",
    timeline: 80,
    coords: { north: "63.01352", east: "14.02354" },
    content: content,
    symbol: symbol
}

