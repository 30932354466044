import React, { createContext } from "react"
import Texts from "./texts"

export const AppContext = createContext(null)

export const initialState = {
    current: -1,
    items: Texts
}

export function reducer(state = initialState, action) {
    switch (action.type) {
        case "CURRENT_SET":
            return { ...state, current: action.index }
        default:
            return state
    }
}
