import React, { useRef, useEffect } from "react"
import { useInview } from "../hooks"
import anime from 'animejs/lib/anime.es.js'

export default function BackButton({ fade, nav }) {
    const r = useRef()
    const inview = useInview(r)

    useEffect(() => {
        if (inview) {
            anime({
                targets: ".content-container .back-button",
                easing: "easeInOutExpo",
                translateX: fade ? [0, 15] : [15, 0],
                opacity: fade ? [1, 0] : [0, 1],
                duration: fade ? 600 : 1000
            })
        }
    }, [fade, inview])

    return (
        <button ref={r} onClick={() => nav("/")} className="back-button">
            <span className="shaft" />
        </button>
    )
}
