import React, { useContext, useEffect } from "react"
import { AppContext } from "../store"
import anime from 'animejs/lib/anime.es.js'

export default function Footer({ fade, delay, hidden, showCoords }) {
    const { state } = useContext(AppContext)

    useEffect(() => {
        anime({
            targets: "footer",
            easing: "easeInOutExpo",
            opacity: fade ? [1, 0] : [0, 1],
            duration: fade ? 600 : 1000,
            delay: showCoords ? 0 : (delay || 0)
        })
    }, [fade])

    const left = (state.current == -1 || hidden)
        ? "x"
        : state.items[state.current].side

    const right = (state.current == -1 || hidden)
        ? "xx"
        : state.items[state.current].nr

    return (
        <footer>
            <div className="footer-inner">
                <div className="left">
                    {left}
                </div>
                <div className="right">
                    {right}
                </div>
            </div>
        </footer>
    )
}
