import React, { useState, useContext, useEffect, useRef } from "react"
import { AppContext } from "../store"
import anime from 'animejs/lib/anime.es.js'

export default function CodeInput({ fade, nav }) {
    const { state, dispatch } = useContext(AppContext)
    const [incorrect, setIncorrect] = useState(false)
    const r = useRef()

    useEffect(() => {
        anime.timeline({ loop: false })
            .add({
                targets: ".code-input .line",
                easing: "easeInOutExpo",
                opacity: fade ? [1, 0] : [0, 1],
                translateX: fade ? [0, -100] : [-100, 0],
                duration: 1200
            }, 0)

            .add({
                targets: ".code-input input",
                easing: "easeInOutExpo",
                opacity: fade ? [1, 0] : [0, 1],
                translateX: fade ? [0, -100] : [-100, 0],
                duration: 1200
            }, 0)

            .add({
                targets: ".code-input button",
                easing: "easeInOutExpo",
                opacity: fade ? [1, 0] : [0, 1],
                translateX: fade ? [0, 100] : [100, 0],
                duration: 1200
            }, 0)

    }, [fade])

    // Annoying @ android
    // useEffect(() => r.current.focus(), [])

    const onSubmit = e => {
        e.preventDefault()

        const code = r.current.value.toLowerCase()

        const [isCorrect, index] = state.items.reduce((acc, n, i) => {
            if (n.code == code) {
                acc = [true, i]
            }

            return acc
        }, [false, -1])

        if (isCorrect) {
            nav("/content")
            dispatch({ type: "CURRENT_SET", index: index })
        } else {
            setIncorrect(true)
            anime({
                targets: ".code-input button",
                keyframes: [
                    { translateX: 12, duration: 120 },
                    { translateX: 0 }
                ],
                easing: "easeOutElastic",
                duration: 800
            })
        }
    }

    const onChange = e => {
        if (incorrect) {
            setIncorrect(false)
        }
    }

    return (
        <div className={incorrect ? "code-input err" : "code-input"}>
            <div className="code-input-inner">
                <form onSubmit={onSubmit}>
                    <input
                        tabIndex={0}
                        ref={r}
                        type="text"
                        placeholder="Nyckelord"
                        onChange={onChange} />

                    <button type="submit">
                        <span className="shaft" />
                    </button>
                </form>

                <div className="line" />
            </div>
        </div>
    )
}
